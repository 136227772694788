import React from "react";
import { NavLink } from "react-router-dom";
export const RouterLink = ({ url, primary, title, styles }) => {
  return (
    <NavLink
      to={`${url}`}
      className={`link ${primary && "primary"}`}
      style={styles}
    >
      {title}
    </NavLink>
  );
};
