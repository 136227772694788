import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
export const LanguageContext = createContext({});

export const fetchLanguages = async () => {
  const response = await axios.get("/data/languages.json");
  const data = response.data;
  return data;
};

export const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");
  const [languageChanged, setLanguageChanged] = useState("en");
  const { data, isSuccess } = useQuery(["languages"], fetchLanguages, {
    staleTime: 300000,
  });

  const changeLanguage = (language) => {
    const timeout = 500
    if (!data.includes(language)) return;
    setLanguage(language);
    setLanguageChanged(true)
    setTimeout(() => setLanguageChanged(false), timeout)
    window.localStorage.setItem("language", language);
  };

  const getSavedLanguage = () => {
    const language = window.localStorage.getItem("language");
    if (data && !data.includes(language)) return setLanguage("en");
    if (language !== "en") setLanguage(language);
  };

  const getTranslatedData = (element) =>{
    return element[language] ? element[language] : "No Text Found";
  }

  const extractTranslations = (obj) => {
    const translations = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const element = obj[key];
        if (typeof element === "number" || typeof element === "string" || typeof element === 'boolean') {
          translations[key] = element;
        } else if (Array.isArray(element)) {
          translations[key] = element.map((item) => {
            if (typeof item === 'string') {
              return item;
            } else if (Array.isArray(item)) {
              return item.map((subItem) => {
                return typeof subItem === 'string' ? subItem : extractTranslations(subItem);
              });
            } else {
              return extractTranslations(item);
            }
          });
        } else if (typeof element === "object" && element.hasOwnProperty(language)) {
          translations[key] = element[language];
        } else if (typeof element === "object") {
          translations[key] = extractTranslations(element);
        }
      }
    }
    return translations;
  };
  useEffect(() => {
    getSavedLanguage();
  }, [data]);
  return (
    <LanguageContext.Provider
      value={{ language, changeLanguage, getTranslatedData, extractTranslations, languageChanged }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
